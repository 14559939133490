import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button
} from "reactstrap";

import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import { Steps, Panel, Notification, Timeline, Paragraph } from 'rsuite';

class ConsumerNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      show: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then(
          (result) => {
            // //console.log(result);
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    };
  }

  setShow(boolean) {
    this.setState({
      show: boolean
    });
    // this.state.show = false;
    //console.log(this.state.show);
  }

  handleClick = instanceId => {
    localStorage.removeItem('token');
  }

  deleteUser(Id) {
    //console.log(Id);
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('token')) {
        axios.delete(process.env.REACT_APP_API_LINK + '/api/user/delete/' + Id + "/", {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
          .then(res => {
            this.handleClick();
            window.location.reload(false);
          })
      }
    };
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {/* {this.props.brandText} */}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.user.Name} {this.state.user.LastName}  <i className="fa fa-caret-down" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/consumer/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Προφίλ</span>
                  </DropdownItem>
                  <DropdownItem to="/consumer/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Ρυθμίσεις</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/consumer/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem> */}
                  {this.state.user.Email != 'deletedusers@rae.gr' ?
                  <>
                  <DropdownItem onClick={() => this.setShow(true)}>
                    <i className="ni ni-single-02" />
                    <span>Διαγραφή Χρήστη</span>
                  </DropdownItem>
                  </> : <></>
                  }
                  <DropdownItem divider />
                  <DropdownItem href="/auth/logout" onClick={() => { this.handleClick() }}>
                    <i className="ni ni-button-power" />
                    <span>Αποσύνδεση</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>

        <Modal
          show={this.state.show}
          onHide={() => this.setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ opacity: 1 }}
          onRequestClose={() => this.setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ marginTop: '-50px' }}>
              Σας ενημερώνουμε ότι επιλέγοντας να διαγράψετε την καρτέλα σας στο my.rae.gr, δεν θα μπορείτε να ανακτήσετε το ιστορικό των αιτημάτων σας, ακόμα και αν αργότερα επανεγγραφείτε με τα ίδια στοιχεία. Μπορείτε να αποθηκεύσετε το ιστορικό αιτημάτων σας πριν προχωρήσετε στη διαγραφή του λογαριασμού σας. <br /><br />
              <Button
                color="primary"
                href={"/consumer/historyexport"}
                size="sm"
              >
                Εξαγωγή Αιτημάτων
              </Button>
              <Button
                color="danger"
                onClick={() =>  this.deleteUser(this.state.user.Id) }
                size="sm"
              >
                Διαγραφή Χρήστη
              </Button>
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ConsumerNavbar;
