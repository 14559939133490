import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';

class ConsumerIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      showModal: false,
      isThereAnOngoingInstance: false
    };
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          // console.log(result);
          this.setState({
            userDetails: result
          });
        })

      axios.get(process.env.REACT_APP_API_LINK + "/api/consumer/instances/current/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          // console.log(res.data);

          var WorkflowInstance_Table = [];

          res.data.forEach((item) => {
            item[0].user = item[1].Receiver[0];
            WorkflowInstance_Table.push(item[0]);
          });

          // var myuser = this.state.user.PersonRole;
          const instances2 = WorkflowInstance_Table;
          instances2.forEach(function (part, index) {
            this[index].NewStatus = this[index].Status == 1 ? "1. Υποβολή Αιτήματος" : this[index].Status == 2 ? "2. Επεξεργασία Αιτήματος" : this[index].Status == 3 ? "3. Αίτημα προς Ολοκλήρωση" : this[index].Status == 4 ? "4. Απευθείας Απόρριψη" : this[index].Status == 5 ? "5. Ολοκλήρωση Αιτήματος" : "";
            // this[index].NewSerialNumber = parseInt(this[index].SerialNumber + '00' + (this[index].SubmittedAt.toString()).substring(0, 4));
            // var dateArray = ((this[index].SubmittedAt).toString()).split('T');
            // var timeArray = dateArray[1].split(':');
            // this[index].NewSubmittedAt = dateArray[0] + ' - ' + timeArray[0] + ':' + timeArray[1];
            // this[index].Data = res[1].Data[index];
            this[index].Buttons =
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/consumer/instance/" + this[index].Id}
                  size="sm"
                  title="Στοιχεία"
                >
                  <i className="fas fa-eye" />
                </Button>
              </React.Fragment>

          }, instances2);
          this.state.datas = {
            columns: [
              {
                label: 'Αριθμος Αιτησης ▼',
                field: 'SerialNumber',
                sort: 'desc',
                width: 100,
                viewColumns: false
              },
              {
                label: 'Κατάσταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Παραλήπτης ▼',
                field: 'user',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Ενέργειες ▼',
                field: 'Buttons',
                sort: 'asc',
                width: 100
              },
            ],
            rows: WorkflowInstance_Table,
          };

          this.setState({
            instances: WorkflowInstance_Table
          });
          if (this.state.instances.length > 0) {
            this.setState({ isThereAnOngoingInstance: true, lastInstanceSerialNo: this.state.instances[0].SerialNumber.toString() })
          }
        })

      await fetch(process.env.REACT_APP_API_LINK + "/api/emails/" + this.state.userDetails.Email + "/", {
        method: 'get',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(res => res.json())
        .then((result) => {
          var counter = 1;
          console.log(result);

          this.setState({
            lastEmails: result
          });

          result.forEach(function (part, index) {
            this[index].DateSent = this[index].DateSent.split('T')[0];
            this[index].mail_no = counter++;
            this[index].Message = <a href={this[index].Message}>{this[index].Message}</a>
          }, result);

          this.setState({
            dummy: {
              columns: [
                {
                  label: 'Αριθμος ▼',
                  field: 'mail_no',
                  sort: 'asc',
                  width: 100
                },
                {
                  label: 'Ημερομηνια ▼',
                  field: 'DateSent',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Συνδεσμος ▼',
                  field: 'Message',
                  sort: 'asc',
                  width: 150
                },

              ],
              rows: result,
            }
          })


        })
    };
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  }

  handleJustCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
    window.location.replace("/consumer/newinstance");
  }

  handleClick = appId => {
    if (localStorage.getItem('token')) {
      axios.delete(process.env.REACT_APP_API_LINK + '/api/applications/' + appId + "/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
        .then(res => {
          window.location.reload(false);
        })
    }
  }

  render() {
    const { showModal, instances } = this.state;
    return (
      <>
        <GenericHeader />
        <Modal isOpen={this.state.showModal} toggle={this.handleJustCloseModal} className="modal-90w" fade={false}>
          <ModalHeader toggle={this.handleJustCloseModal}></ModalHeader>
          <ModalBody>
            <div>
              <p>
                <strong>Αγαπητέ καταναλωτή, παρατηρούμε πως υπάρχει ήδη μια ενεργή αναφορά σας με τον Α/Α: {this.state.lastInstanceSerialNo}.</strong> Αν θέλετε να συντάξετε μια νέα αναφορά για κάποιο άλλο θέμα παρακαλούμε πατήστε "Nαι" για τη συνέχεια της διαδικασίας, αλλιώς παρακαλούμε
                όπως πατήσετε "Οχι" μέχρι και να ολοκληρωθεί η διαδικασία του αρχικού σας αιτήματος. Ευχαριστούμε πολύ!
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.handleCloseModal}>
              Ναι
            </Button>
            <Button color="danger" onClick={this.handleJustCloseModal}>
              Όχι
            </Button>
          </ModalFooter>
        </Modal>

        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Υποβεβλημένα αιτήματα</h3>
                    </div>
                    {this.state.userDetails.Email != "deletedusers@rae.gr" ?
                      <>
                        <div className="col text-right">
                          {this.state.isThereAnOngoingInstance ? (
                            <Button
                              color="success"
                              size="sm"
                              onClick={this.handleShowModal}
                            >
                              Νέο αίτημα
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              size="sm"
                              href="/consumer/newinstance"
                            >
                              Νέο αίτημα
                            </Button>
                          )}
                          <Button
                            color="warning"
                            href={"/consumer/historyexport"}
                            size="sm"
                          >
                            Εξαγωγή Αιτημάτων
                          </Button>
                          <Button
                            color="primary"
                            href={"/consumer/instances"}
                            size="sm"
                          >
                            Προβολή όλων

                          </Button>
                        </div>
                      </>
                      : <></>
                    }
                  </Row>
                </CardHeader>

                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <MDBDataTable
                        bordered
                        exportToCSV
                        hover
                        responsive
                        strip
                        data={this.state.datas}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                      />
                    </div>

                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {this.state.userDetails.Email != "deletedusers@rae.gr" ?
            <>
              <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 style={{ marginTop: '20px', paddingBottom: '25px' }} className="mb-0">Ιστορικό 20 τελευταίων email: </h3>

                          <MDBDataTable
                            bordered
                            exportToCSV
                            hover
                            responsive
                            strip
                            data={this.state.dummy}
                            id="mdbdttable"
                            entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                            noDataComponent="Δεν υπάρχουν δεδομένα"
                            searchLabel="Αναζήτηση"
                            paginationLabel={['Προηγούμενο', 'Επόμενο']}
                            infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                            entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                          />
                        </div>
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </> : <></>
          }


          <div class='push'></div>
        </Container>
      </>
    );
  }
}

export default ConsumerIndex;
