import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import dedaBackground from "../../assets/img/rae-bg-consumers.jpg";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import FacebookLogin from 'react-facebook-login';
import { FacebookLoginButton } from "react-social-login-buttons";

import '../../assets/css/login.css';

import GoogleLogin from 'react-google-login';

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + '/api/auth/token/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function loginUserFacebook(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + '/api/auth/tokenfacebook/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function loginUserGoogle(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + '/api/auth/tokengoogle/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}



export default function Login({ setToken }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [erroremail, setErrorEmail] = useState(false);
  const [errorpass, setErrorPass] = useState(false);
  const [platform, setPlatform] = useState("consumer");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const [show, setShow] = useState(false);

  // // console.log(email);
  // // console.log(password);
  const handleClose = () => {
    setShowModal(false);
    window.location.replace("/admin/index");
  };

  const handleSubmit = async e => {
    // // console.log(email);
    // // console.log(password);

    e.preventDefault();
    const auth = await loginUser({
      email,
      password,
      platform
    });



    if (auth.detail == "Incorrect username or password") {
      // // console.log("Incorrect email and/or password");
      setErrorEmail(true);
    }
    // The below does not work

    if (auth.errors) {
      setErrorEmail(false);
      setErrorPass(false);
      if (auth.errors == 'Email not exists') {
        alert('caught wrong email');
        setErrorEmail(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
      if (auth.errors == 'Password Incorrect') {
        alert('caught wrong pass');
        setErrorPass(true);
        localStorage.removeItem('token');
        // window.location.replace("/");
      }
    };
    if (auth.access_token) {
      setToken(String(auth.access_token));
      // // console.log(auth.access_token);
      setShowModal(true);
    };

  }

  const responseGoogle = async (response) => {
    if (response.googleId) {
      let name = response.profileObj.name.split(" ")[0];
      let lastname = response.profileObj.name.split(" ")[1];
      let userEmail = response.profileObj.email;

      await fetch(process.env.REACT_APP_API_LINK + '/api/consumer/newfromgoogle/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'Name': name,
          'LastName': lastname,
          'Email': userEmail,
          'Id': response.googleId
        })
      })
        .then(res => res.json())
        .then(data => {



          (async () => {
            const auth = await loginUserGoogle({
              GoogleId: response.googleId,
              AccessToken: response.accessToken,
              GoogleEmail: userEmail,
              platform: platform
            });

            console.log(auth);

            if (auth.detail == "Incorrect username or password") {
              // // console.log("Incorrect email and/or password");
              setErrorEmail(true);
            }

            if (!auth.access_token) {
              alert('A user with this email already exists!');
            }
            // The below does not work

            if (auth.errors) {
              setErrorEmail(false);
              setErrorPass(false);
              if (auth.errors == 'Email not exists') {
                alert('caught wrong email');
                setErrorEmail(true);
                localStorage.removeItem('token');
                // window.location.replace("/");
              }
              if (auth.errors == 'Password Incorrect') {
                alert('caught wrong pass');
                setErrorPass(true);
                localStorage.removeItem('token');
                // window.location.replace("/");
              }
            };
            if (auth.access_token) {
              setToken(String(auth.access_token));
              setShowModal(true);
            };
          })().catch(e => console.log("Caught: " + e));
          // console.log(data);

        },
        )

      // console.log(response.googleId);
      // console.log(response.profileObj.email);
    } //  else {
    //   alert("Σφάλμα κατά την σύνδεση με τον διακομιστή");
    // }
    console.log(response);




  }

  const responseFacebook = async (response) => {

    if (response.accessToken) {
      let name = response.name.split(" ")[0];
      let lastname = response.name.split(" ")[1];
      let userEmail = response.userID + "@facebook.com";

      if (response.email)
        userEmail = response.email;

      await fetch(process.env.REACT_APP_API_LINK + '/api/consumer/newfromfacebook/', {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }),
        body: JSON.stringify({
          'Name': name,
          'LastName': lastname,
          'Email': userEmail,
          'Id': response.userID
        })
      })
        .then(data => {
          (async () => {
            console.log(platform);
            const auth = await loginUserFacebook({
              FacebookId: response.userID,
              AccessToken: response.accessToken,
              platform: platform
            });


            console.log(auth);

            if (auth.detail == "Incorrect username or password") {
              // // console.log("Incorrect email and/or password");
              setErrorEmail(true);
            }
            // The below does not work

            if (auth.errors) {
              setErrorEmail(false);
              setErrorPass(false);
              if (auth.errors == 'Email not exists') {
                alert('caught wrong email');
                setErrorEmail(true);
                localStorage.removeItem('token');
                // window.location.replace("/");
              }
              if (auth.errors == 'Password Incorrect') {
                alert('caught wrong pass');
                setErrorPass(true);
                localStorage.removeItem('token');
                // window.location.replace("/");
              }
            };
            if (auth.access_token) {
              setToken(String(auth.access_token));
              setShowModal(true);
            };
          })().catch(e => console.log("Caught: " + e));
          // console.log(data);

        },
        )
    }



    // console.log(response);
  }

  return (
    <div className="main-content" style={{ backgroundImage: `url(${dedaBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed", height: "100vh" }}>
      <AuthNavbar />
      <div className="header py-7 py-lg-7" ></div>

      <Container className="mt--8 pb-5">
        <Modal show={showModal} onHide={handleClose}
          dialogClassName="custom-modal"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ opacity: 1 }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Αγαπητέ καταναλωτή, καλώς ορίσατε στο My.Rae.gr!</h1>
              <p>
                {/* Για την ορθή ενημέρωσή σας σχετικά με το ρόλο της ΡΑΑΕΥ και τη λειτουργία του My.Rae.gr παρακαλούμε θερμά όπως πατήσετε <strong><a href="/consumer/faq">εδώ.</a></strong> */}
                Αρχικά θα θέλαμε να σας ευχαριστήσουμε που χρησιμοποιείτε την πλατφόρμα του My.Rae.gr για την ενημέρωσή σας σχετικά με θέματα της αγοράς ενέργειας ή/και για την υποβολή της αναφοράς σας. Στην περίπτωση που αποφασίσετε να υποβάλετε αναφορά μέσω της πλατφόρμας, για την καλύτερη εξυπηρέτησή σας θα θέλαμε να σας προτείνουμε μερικές προτάσεις κατηγοριοποίησης όπως:
                <ul>
                  <li>"Δεν ενημερώθηκα για την τροποποίηση της σύμβασης προμήθειας" --&gt; Τον λογαριασμό μου -&gt; Την τροποποίηση Συμβατικών Όρων & Χρεώσεων</li>
                  <li>"Έχω στείλει ερώτημα προς την εταιρεία και ακόμα δε μου έχουν απαντήσει" --&gt; Την εξυπηρέτησή μου από την εταιρεία -&gt; Τον χρόνο ανταπόκρισης από πλευράς της εταιρείας</li>
                  <li><strong>"Κατά τη σύναψη της σύμβασης</strong> με την εταιρεία, δεν ενημερώθηκα για την εφαρμογή Ρήτρας Αναπροσαρμογής" --&gt; Την Σύμβαση με την εταιρεία -&gt; Την ενημέρωση που έλαβα για τους όρους και τις υπηρεσίες που περιλαμβάνει η Σύμβαση</li>
                  <li><strong>"Δεν καταλαβαίνω πως βγαίνει τόσο ακριβή η Ρήτρα Αναπροσαραμογής στον Λογαριασμό μου"</strong> --&gt; Τον λογαριασμό μου -&gt; Τις Ρήτρες στον Λογαριασμό (ΟΤΣ, Αποχώρησης)</li>
                  <li>"Θέλω να αλλάξω Πάροχο και η εταιρεία δε με αφήνει" --&gt; Την αλλαγή Προμηθευτή -&gt; Την άρνηση από την εταιρεία για αλλαγή Προμηθευτή</li>
                  <li>"Έγινε λανθασμένη καταγραφή της ενέργειας που κατανάλωσα" --&gt; Μετρήσεις κατανάλωσης -&gt; Λανθασμένη Καταμέτρηση</li>
                </ul>
              </p>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Συνέχεια
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ marginTop: '-50px' }}>
              <strong>Το MyRAE, αποτελεί διαδικτυακή εφαρμογή της Ρυθμιστικής Αρχής Ενέργειας, στην οποία οι καταναλωτές μπορούν να εγγράφονται και να αποκτούν πρόσβαση σε σειρά υπηρεσιών όπως: </strong> <br />

              <br /> <li>Αποστολή φόρμας παραπόνων προς τους Διαχειριστές Δικτύων και τους Παρόχους Υπηρεσιών Ηλεκτρικής Ενέργειας και Φυσικού Αερίου. </li>

              <li>Λήψη ενημερώσεων για τις δημόσιες διαβουλεύσεις της ΡΑΕ. </li>

              <li> Λήψη εξειδικευμένων ενημερώσεων από το σύνολο των ανακοινώσεων της ΡΑΕ με βάση τα κριτήρια που ενδιαφέρουν τον κάθε καταναλωτή. </li>

              <li> Συμμετοχή σε έρευνες ικανοποίησης για τις παρεχόμενες υπηρεσίες από τη ΡΑΕ, τους Διαχειριστές Δικτύων, και τους Παρόχους Ενέργειας. </li>
            </p>
          </Modal.Body>
        </Modal>

        <Row className="justify-content-center">

          <Col lg="5" md="7" >
            <Card className="shadow border-0" style={{ backgroundColor: "rgba(183,180,183,0.6)" }}>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="text" onChange={e => setEmail(e.target.value)} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Κωδικός" type="password" onChange={e => setPassword(e.target.value)} />
                    </InputGroup>
                  </FormGroup>
                  <a
                    className="text-light"
                    href={"/forgotpassword/index"}
                  // onClick={e => e.preventDefault()}
                  >
                    <small style={{ textAlign: 'center', color: '#000' }}>Ξεχάσατε τον κωδικό σας ;</small>
                  </a>
                  <div>
                    <a className="text-light" href="https://www.rae.gr/terms-of-use-myrae/" target="_blank" rel="noopener noreferrer">
                      <small style={{ textAlign: 'center', color: 'white' }}> Όροι</small>
                    </a>
                    <small style={{ textAlign: 'center', color: '#000' }}> και</small>
                    <a className="text-light" href="https://www.rae.gr/processing-of-personal-data-myrae/" target="_blank" rel="noopener noreferrer">
                      <small style={{ textAlign: 'center', color: 'white' }}> Προϋποθέσεις</small>
                    </a>
                  </div>
                  {erroremail ? <p style={{ color: 'orange' }}>To email ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.</p> : <></>}
                  {errorpass ? <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="text" style={{ color: 'red' }} /> : <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="hidden" style={{ color: 'red' }} />}

                  <div className="text-center">
                    <Button className="my-3" color="primary" type="submit" style={{ width: '180px' }}>
                      Σύνδεση
                    </Button>
                  </div>
                  <div className="text-center">
                    <Button className="my-3" color="warning" type="button" href={"/signup/index"} style={{ width: '180px' }}>
                      Γρήγορη Εγγραφή
                    </Button>
                  </div>
                  <div className="text-center" style={{ color: 'white', fontSize: '18px', marginTop: '20px', fontWeight: '600' }}>
                    Εναλλακτικά Συνδεθείτε μέσω Social Media:
                  </div>

                  <div class="container" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div class="row">
                      <div class="col-xs-6">
                        <FacebookLogin
                          appId="1030941481088719"
                          autoLoad={false}
                          fields="name,email,picture"
                          // onClick={componentClicked}
                          callback={responseFacebook}
                          // cssClass="my-facebook-button-class"
                          cssClass="kep-login-facebook"
                          icon="fa-facebook"
                          textButton={" Facebook"}
                          disableMobileRedirect={true}
                        />
                      </div>
                      <div class="col-xs-6 google-container" style={{ marginTop: '30px', marginLeft: '20px' }}>
                        <GoogleLogin
                          clientId="64942789802-k06u7c9alcfct0flpl38s8bd3d80edpf.apps.googleusercontent.com"
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        // buttonText=" Google"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="container"> */}



                  <div className="text-right" style={{ marginBottom: '-90px' }}>
                    <Button title='Πληροφορίες Συστήματος' className="my-5" color="basic" type="button" onClick={() => setShow(true)} style={{ width: '50px', height: '50px', borderRadius: '100%', textAlign: 'center' }}>
                      <i className="fas fa-info" />
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>

        </Row>


      </Container>
    </div>


  )
}

// <form onSubmit={handleSubmit}>
//   <label>
//     <p>Email</p>
//     <input type="text" onChange={e => setEmail(e.target.value)}/>
//   </label>
//   <label>
//     <p>Password</p>
//     <input type="password" onChange={e => setPassword(e.target.value)}/>
//   </label>
//   <div>
//     <button type="submit">Submit</button>
//   </div>
// </form>

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}