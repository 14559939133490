import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import 'moment/locale/el';

import { MDBDataTable } from 'mdbreact';

import '../../../assets/css/datatable.css';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class historyExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            data: [],
            serialnumbers: [],
            userDetails: [],
            userEmail: '',
            userPhone: ''
        };

    }

    componentDidMount() {
        document.getElementById('sidenav-main').style.display = 'none';
        document.getElementById('navbar-main').style.display = 'none';
        document.querySelector('.main-content').style.margin = '0px';

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_LINK + "/api/export/historyexport/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    // console.log(result[1]);
                    this.setState({
                        data: result[1],
                        serialnumbers: result[0]
                    })

                    console.log(result);


                })

            fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        userDetails: result,
                        userEmail: result.Email,
                        userPhone: ''
                    });
                })
        }

        
    }

    print() {
        // printButton
        document.getElementById('printSection').style.display = 'none';
        window.print();
    }

    render() {
        window.onafterprint = function () {
            // console.log("Printing completed...");
            document.getElementById('printSection').style.display = 'block';
        }
        return (
            <>

                <div class="container-fluid" style={{ margin: '0' }}>
                    <img
                        alt="..."
                        src={"/static/media/logo.8e7dab4b.jpg"}
                        width="200"
                    /> <br /> <br />
                    <b>Στοιχεία Καταναλωτή: </b> <br />
                    Όνομα: {this.state.userDetails.Name} <br />
                    Επώνυμο: {this.state.userDetails.LastName} <br />
                    {this.state.userEmail.includes("@facebook.com") ?
                        <></> 
                        : 
                        <>
                            Email: {this.state.userDetails.Email} <br />
                        </>

                    }
                    {this.state.userPhone == ("0000000000") ?
                        <></> 
                        : 
                        <>
                            Τηλέφωνο: {this.state.userDetails.phoneNumber} <br />
                        </>

                    }

                    {/* <GenericHeader /> */}
                    <div id="printSection" style={{ marginTop: '20px', zIndex: '1000', marginBottom: '20px' }}>

                        <Button
                            color="primary"
                            size="sm"
                            title="Στοιχεία"
                            onClick={() => this.print()}
                        >
                            Εκτύπωση
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            title="Στοιχεία"
                            href="/consumer/index"
                        >
                            Επιστροφή στο myRae
                        </Button>
                    </div>
                    <br />
                    <b style={{ fontSize: '20px' }}>Αιτήματα Καταναλωτή: </b> <br />
                    {this.state.serialnumbers.map(serialno => (

                        <div className="col-md-12">

                            <hr />

                            Αριθμός Αιτήματος: {serialno}
                            {this.state.data[serialno].map((data, index) => (
                                <div className="col-md-12">

                                    {index == 0 ? <>Είδος Ενέργειας: </> : <></>}
                                    {index == 1 ? <>Κατηγορία Παραλήπτη Αιτήματος: </> : <></>}
                                    {index == 2 ? <>Προμηθευτής/Διαχειριστής: </> : <></>}
                                    {index == 3 ? <>Όνομα Κατόχου: </> : <></>}
                                    {index == 4 ? <>Επίθετο Κατόχου: </> : <></>}
                                    {index == 5 ? <>ΑΦΜ Κατόχου: </> : <></>}
                                    {index == 6 ? <>Αριθμός Παροχής: </> : <></>}
                                    {index == 10 ? <>Κατηγορία Αιτήματος: </> : <></>}
                                    {index == 11 ? <>Σχόλια/Παρατηρήσεις: </> : <></>}
                                    {index == 12 && this.state.data[serialno].length > 13 ? <>Διάλογος μεταξύ παραλήπτη και καταναλωτή: </> : <></>}
                                    {index == 13 ? <>Προμηθευτής/Διαχειριστής: </> : <></>}

                                    {index == 15 ? <>
                                        {data.map((reply, index) => (
                                            <React.Fragment>
                                                {reply.UserType == 1 ?
                                                    <>
                                                        Προμηθευτής/Διαχειριστής:
                                                    </> : <>
                                                        Καταναλωτής:
                                                    </>
                                                }
                                                {reply.Message}
                                                <br />
                                            </React.Fragment>


                                        ))} <br />

                                    </> : <></>}
                                    {data.Data}
                                </div>
                            ))}

                            <br />

                        </div>
                    ))}


                </div>
            </>
        )
    };

}

export default historyExport;